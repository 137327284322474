$arrowRight: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05Ljk0ODUyIDQuMzI5MDdDMTAuMjkxNSAzLjk2OTI2IDEwLjg2MTEgMy45NTU1OCAxMS4yMjA5IDQuMjk4NTJMMjEuNjYwOSAxNC4yNDg5TDIxLjY3NTYgMTQuMjYzMkMyMS44NzEyIDE0LjQ1ODMgMjIuMDI2MyAxNC42OSAyMi4xMzIyIDE0Ljk0NTFDMjIuMjM4MSAxNS4yMDAzIDIyLjI5MjYgMTUuNDczOCAyMi4yOTI2IDE1Ljc1QzIyLjI5MjYgMTYuMDI2MiAyMi4yMzgxIDE2LjI5OTcgMjIuMTMyMiAxNi41NTQ5QzIyLjAyNjMgMTYuODEgMjEuODcxMiAxNy4wNDE3IDIxLjY3NTYgMTcuMjM2OEwyMS42NjA5IDE3LjI1MTFMMTEuMjIwOSAyNy4yMDE1QzEwLjg2MTEgMjcuNTQ0NCAxMC4yOTE1IDI3LjUzMDcgOS45NDg1MiAyNy4xNzA5QzkuNjA1NTggMjYuODExMSA5LjYxOTI2IDI2LjI0MTQgOS45NzkwNyAyNS44OTg1TDIwLjQwOTIgMTUuOTU3NUMyMC40MzQ5IDE1LjkzMDcgMjAuNDU1NCAxNS44OTkzIDIwLjQ2OTYgMTUuODY1QzIwLjQ4NDggMTUuODI4NSAyMC40OTI2IDE1Ljc4OTUgMjAuNDkyNiAxNS43NUMyMC40OTI2IDE1LjcxMDUgMjAuNDg0OCAxNS42NzE1IDIwLjQ2OTYgMTUuNjM1QzIwLjQ1NTQgMTUuNjAwNyAyMC40MzQ5IDE1LjU2OTMgMjAuNDA5MiAxNS41NDI1TDkuOTc5MDcgNS42MDE0OUM5LjYxOTI2IDUuMjU4NTYgOS42MDU1OCA0LjY4ODg4IDkuOTQ4NTIgNC4zMjkwN1oiIGZpbGw9IiM4OThFQTMiLz4KPC9zdmc+Cg==';

.markdown {
  @apply text-gray-950
    dark:text-white;

  > *:last-child {
    @apply mb-0 #{!important};
  }

  h1 {
    @apply font-sans
      font-medium
      text-18px
      leading-5.5
      mb-6
      text-gray-950
      dark:text-white;
  }

  %headings {
    @apply font-sans
      font-bold
      mb-2
      leading-3.5
      text-gray-950
      dark:text-gray-500;
  }

  h2 {
    @extend %headings;
    @apply text-13px
      mt-6;

    &:first-child {
      @apply mt-0;
    }
  }

  h3 {
    @extend %headings;
    @apply text-14px;
  }

  h4 {
    @extend %headings;
    @apply text-12px
      mb-1
      leading-5.5;
  }

  p {
    @apply font-sans
    text-14px
    leading-5.5
    mb-4
    text-gray-950
    dark:text-white
    break-words;
  }

  strong {
    @apply font-bold
      text-red-500;
  }

  code {
    @apply font-sans
      bg-blue-500
        dark:text-white
        bg-opacity-30
        px-1
        py-0.5
        mx-px
        break-all
        rounded
        relative;

    .copy {
      @apply absolute
        flex
        items-center
        justify-center
        cursor-pointer
        top-2
        right-2
        z-50
        border
        border-gray-500
        py-1
        px-1
        rounded
        hover:shadow
        dark:hover:shadow-sm
        invisible
        transition-all
        ease-in-out
        duration-200
        opacity-0;

      svg {
        @apply fill-gray-500 w-3.5 h-3.5;
      }
    }

    &:hover {
      .copy {
        @apply visible
          opacity-100;
      }
    }
  }

  blockquote {
    @extend p;
    @apply italic
      py-4
      px-8
      mb-10
      border-l-2
      border-solid
      border-pink-500
      bg-gray-50
      dark:bg-gray-925;

    blockquote {
      @apply mt-6
          bg-gray-100
          dark:bg-gray-900
          border-gray-200
          dark:border-gray-700;
    }

    p:last-child {
      @apply mb-0;
    }
  }

  ul,
  ol {
    @apply ml-8;

    li {
      @extend p;
    }

    ul,
    ol {
      @apply mt-2;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  hr {
    @apply mb-4
      border-none
      h-px
      bg-gray-200
      dark:bg-gray-700;
  }

  a {
    @extend p;
    @apply no-underline
      cursor-text
      pointer-events-none;
  }

  pre {
    @apply overflow-x-auto
        p-8
        mb-10
        rounded
        bg-gray-50
        dark:bg-gray-925;

    .hljs {
      @apply font-medium
            font-code
            bg-transparent
            m-0
            p-0
            text-12px
            text-gray-950
            dark:text-white;
    }

    .hljs-string {
      @apply text-blue-700
            dark:text-blue-600;

      .hljs-subst {
        @apply text-gray-950
        dark:text-white;

        .hljs-literal {
          @apply text-blue-700
          dark:text-blue-600;
        }
      }
    }

    .hljs-number {
      @apply text-pink-400
            dark:text-pink-300;
    }

    .hljs-title,
    .hljs-property {
      @apply text-gray-950
          dark:text-white;

      &.function_ {
        @apply text-purple-600
              dark:text-purple-400;
      }
    }

    .hljs-built_in,
    .hljs-regexp {
      @apply text-yellow-600
            dark:text-yellow-500;
    }

    .hljs-keyword,
    .hljs-variable {
      @apply text-red-600
            dark:text-red-500;

      &.language_ {
        @apply text-yellow-600
              dark:text-yellow-500;
      }
    }

    .hljs-function > .hljs-keyword {
      @apply text-red-400
            dark:text-red-300
            italic;
    }

    .hljs-params {
      @apply text-green-600
            dark:text-green-500;
    }

    .hljs-comment {
      @apply text-gray-500
            dark:text-gray-400;
    }

    .hljs-tag {
      @apply text-gray-500;

      .hljs-name {
        @apply text-gray-800
            dark:text-blue-600;
      }

      .hljs-attr {
        @apply text-red-600
              dark:text-blue-200;
      }

      .hljs-string {
        @apply text-gray-900
              dark:text-red-300;
      }
    }

    .hljs-meta {
      @apply text-gray-500;

      .hljs-keyword {
        @apply text-gray-800
            dark:text-blue-600;
      }
    }
  }

  table {
    @apply w-full
      border-separate
      border-spacing-y
      mb-10
      table-fixed;

    code {
      @apply bg-transparent
        break-words
        break-all;
    }

    th,
    td {
      @apply px-6
      text-12px
      break-words
      break-all;
    }

    th {
      @apply font-normal
        pt-4
        pb-1
        text-gray-500
        text-left;
    }

    td {
      @apply py-4
        text-gray-950
        dark:text-white
        bg-gray-50
        dark:bg-gray-925;

      &:first-child {
        @apply rounded-tl
          rounded-bl;
      }

      &:last-child {
        @apply rounded-tr
          rounded-br;
      }
    }
  }

  .language-mermaid {
    @apply bg-transparent;

    .label-container,
    circle {
      @apply fill-white
        dark:fill-gray-900
        stroke-transparent #{!important};
    }

    .node {
      @apply break-normal;
    }

    .nodeLabel {
      @apply flex
        gap-4
        text-left
        text-gray-950
        dark:text-white
        bg-transparent #{!important};

      > i {
        @apply flex
          items-center
          justify-center
          text-26px
          w-12
          h-12
          rounded
          text-white
          bg-gray-800;

        &.red {
          @apply bg-gradient-to-b
            from-red-500 to-pink-500;
        }
      }

      > div {
        @apply flex
          flex-col
          justify-center
          font-sans
          text-19px
          pr-8
          leading-[22px];

        > div {
          @apply text-17px
            font-medium
            text-gray-500
            dark:text-gray-300;
        }
      }
    }

    .flowchart-link {
      @apply stroke-gray-500
        dark:stroke-gray-400 #{!important};
    }

    .marker {
      @apply stroke-gray-500
        dark:stroke-gray-400
        fill-gray-500
        dark:fill-gray-400 #{!important};
    }

    .edgeLabel {
      @apply text-gray-950
        dark:text-white
        bg-gray-50
        dark:bg-gray-925
        p-1.5
        #{!important};
    }
  }

  details {
    @apply mb-4
      rounded
      bg-gray-50
      dark:bg-gray-925
      relative;

    &:last-of-type {
      @apply mb-10;
    }

    &[open] {
      @apply pb-6;
    }

    &:last-of-type {
      @apply mb-10;
    }

    &[open] {
      summary {
        &:after {
          @apply rotate-90;
        }
      }
    }

    summary {
      @apply flex
          items-center
          gap-3
          px-7
          py-6
          text-12px
          font-bold
          text-gray-950
          dark:text-white
          list-none
          cursor-pointer
          relative
          z-10;

      > i {
        @apply text-17px;
      }

      &:after {
        @apply content-['']
          absolute
          top-1/2
          -mt-2
          right-7
          w-4
          h-4
          transition-transform
          duration-200
          ease-in-out
          bg-cover
          bg-no-repeat
          bg-center
          bg-[url(#{$arrowRight})];
      }
    }

    > *:last-child {
      @apply mb-0 #{!important};
    }

    pre {
      @apply overflow-hidden
        m-0
        pt-0
        ml-7
        pb-0
        px-7
        pl-0
        max-h-[24.188rem]
        break-all
        whitespace-pre-wrap
        bg-transparent
        dark:bg-transparent;

      code {
        @apply inline-block
          overflow-y-auto
          max-h-96
          w-full
          relative
          py-4
          pl-4
          pr-9
          rounded
          bg-gray-100
          dark:bg-gray-900 #{!important};
      }
    }

    > p {
      @apply px-7
        inline-block
        w-full
        mb-3;

      &:last-child {
        @apply mb-4;
      }
    }

    ol,
    ul {
      @apply m-0
        px-7
        inline-block
        w-full
        list-inside;

      ol,
      ul {
        @apply px-4
          mt-3;
      }

      li {
        @apply text-13px;

        pre {
          @apply p-0
            mt-2;
        }
      }
    }

    table {
      @apply p-7;

      th {
        @apply pt-0;
      }

      td {
        @apply text-gray-950
          dark:text-white
          bg-gray-100
          dark:bg-gray-900 #{!important};
      }
    }

    blockquote {
      @apply ml-7
        mb-6
        w-[calc(100%-3.5rem)]
        bg-gray-100
        dark:bg-gray-900 #{!important};
    }

    h2,
    h3,
    h4 {
      @apply px-7
        mt-0
        mb-4;
    }
  }

  .scroll {
    @apply max-h-[24.188rem]
    overflow-auto
    mb-4;

    > * {
      @apply m-0;
    }
  }

  a[data-br-href] {
    @apply text-gray-500
      dark:text-gray-500
      border-b
      border-solid
      border-gray-500
      dark:border-gray-500
      pointer-events-auto
      cursor-pointer;
  }
}
