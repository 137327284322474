@import 'react-datepicker/dist/react-datepicker.css';

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  @apply border-t-[0.081rem]
    border-r-[0.081rem]
    border-b-0
    border-l-0
    top-4;
}

.react-datepicker__month-year-read-view--down-arrow {
  @apply border-t-[0.081rem]
  border-r-[0.081rem]
  border-b-0
  border-l-0
  top-4;
}

.react-datepicker__navigation-icon {
  &::before {
    @apply border-gray-500
      border-t-[0.081rem]
      border-r-[0.081rem]
      border-b-0
      border-l-0
      top-0;
  }
}

.react-datepicker__calendar-container {
  @apply flex items-start
  bg-white
  dark:bg-gray-900;
}

.react-datepicker-popper[data-placement^='top'] {
  .react-datepicker__triangle {
    @apply hidden;
  }
}

.react-datepicker-popper[data-placement^='bottom'] {
  .react-datepicker__triangle {
    @apply hidden;
  }
}

.react-datepicker__day--outside-month {
  @apply font-medium
    leading-5
    text-gray-950/20
    dark:text-white/10;
}

.react-datepicker {
  @apply font-sans
    border-none
    bg-transparent
    rounded-r
    shadow-2xl
    dark:shadow-md
    relative;
}

.react-datepicker__calendar-container {
  @apply shadow-2xl
    dark:shadow-md;
}

.react-datepicker__children-container {
  @apply w-[7.625rem]
  absolute
  -left-[7.61rem]
  h-full
  top-0
  bg-white
  dark:bg-gray-900
  m-0
  p-2
  rounded-l
  border-r
  border-r-gray-200
  dark:border-r-gray-800;
}

.react-datepicker__header {
  @apply bg-white
    dark:bg-gray-900
    border-none;
}

.react-datepicker__current-month {
  @apply my-3
    text-gray-950
    dark:text-white
    font-semiBold
    text-13px
    leading-5;
}

.react-datepicker-year-header {
  @apply my-3
    text-gray-950
    dark:text-white
    font-semiBold
    text-13px
    leading-5;
}

.react-datepicker__navigation {
  @apply top-2
    z-[1];
}

.react-datepicker__month-container {
  @apply bg-white
    dark:bg-gray-900
    rounded-r
    h-[328px];
}

.react-datepicker__day-name {
  @apply text-gray-950
    dark:text-white
    font-medium
    text-12px
    w-8;
}

.react-datepicker__day {
  &:not(.react-datepicker__day--outside-month) {
    @apply text-gray-950
      dark:text-white;
  }

  @apply w-[2.313rem]
    h-9
    m-0
    pt-1
    leading-7
    text-13px
    transition-colors
    duration-75
    ease-linear;

  &:not(.react-datepicker__day--today) {
    &:hover {
      @apply rounded-none
        bg-gray-950
        dark:bg-white
        text-white
        dark:text-gray-950;
    }
  }
}

.react-datepicker__month-text {
  @apply cursor-pointer;

  &:hover {
    @apply rounded-none
      bg-gray-950
      dark:bg-white
      text-white
      dark:text-gray-950;
  }
}

.react-datepicker__quarter-text {
  @apply cursor-pointer;

  &:hover {
    @apply rounded-none
      bg-gray-950
      dark:bg-white
      text-white
      dark:text-gray-950;
  }
}

.react-datepicker__year-text {
  @apply cursor-pointer;

  &:hover {
    @apply rounded-none
      bg-gray-950
      dark:bg-white
      text-white
      dark:text-gray-950;
  }
}

.react-datepicker__day--highlighted {
  @apply text-gray-950;
}

.react-datepicker__month-text--highlighted {
  @apply text-gray-950;
}

.react-datepicker__quarter-text--highlighted {
  @apply text-gray-950;
}

.react-datepicker__year-text--highlighted {
  @apply text-gray-950;
}

.react-datepicker__day--today {
  @apply border
  border-solid
  border-gray-950/50
  dark:border-white/25
  bg-transparent
  rounded-none
  outline-none;

  &:hover {
    @apply bg-gray-900
    dark:bg-white
    text-white
    dark:text-gray-950
    rounded-none
    #{!important};
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--range-end,
.react-datepicker__day--range-start,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  &:not(.react-datepicker__day--today) {
    @apply rounded-none
      bg-gray-900
      dark:bg-white
      border-none
      text-white
      dark:text-gray-950
      outline-none
      #{!important};

    &:hover {
      @apply text-white
          dark:text-gray-950
          #{!important};
    }
  }
}

.react-datepicker__day {
  &.react-datepicker__day--in-range,
  &.react-datepicker__day--in-selecting-range {
    &:not(.react-datepicker__day--today) {
      @apply rounded-none
      border-none
      outline-none
      bg-gray-100
      dark:bg-white/20
      text-gray-950
      dark:text-white;

      &:hover {
        @apply bg-gray-200
        dark:bg-white/30;
      }
    }
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  @apply bg-transparent
    border-none
    outline-none;
}

.react-datepicker__week {
  @apply flex h-9;
}

.react-datepicker__day--disabled {
  @apply pointer-events-none
    text-gray-950/20
    dark:text-white/10
    #{!important};
}
